import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = props => {
  return (
    <Layout section="contact">
      <SEO
        title="Contact"
        description="You can contact me for any work inquiries at info@carletex.com"
      />
      <h1>
        <strong>Contact</strong>
      </h1>

      <p>
        For any work inquiries, drop me a line at{" "}
        <a
          href="mailto:info@carletex.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@carletex.com
        </a>
        . You can also find me at:
      </p>

      <ul className="social">
        <li>
          <a
            href="https://www.linkedin.com/in/carlossanchezserrano/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </li>
        <li>
          <a
            href="https://drupal.org/u/carletex"
            target="_blank"
            rel="noopener noreferrer"
          >
            Drupal.org
          </a>
        </li>
        <li>
          <a
            href="https://github.com/carletex"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
        </li>
      </ul>
    </Layout>
  )
}

export default ContactPage
